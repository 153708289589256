import React from "react";
import { AnyAction } from "redux";
import * as actionType from '../../actions/actionTypes/bls';
import { updateObj } from '../../utilities';



export interface BlsState {
    blsUserId: string
    sessionId: string
    blsLength: number

    blsCommand: number[]
    tapperSpeedReport: number
    color: number
    colorIntensity: number
    tapperIntensityReport: number
    tapperFrequencyReport: number
    active: boolean

    serialPort: any,
    writerPort: any,
    readerPort: any,
}

let intialState: BlsState = {
    blsUserId: 'none',
    sessionId: 'none',
    blsLength: 600000,
    blsCommand: [2, 74, 65, 48, 48, 54, 56, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 51, 51, 48, 48, 51, 51, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 52, 48, 51, 14, 10],
    tapperSpeedReport: 50,
    color: 5,
    colorIntensity: 20,
    tapperIntensityReport: 50,
    tapperFrequencyReport: 50,
    active: false,
    serialPort: null,
    writerPort: null,
    readerPort: null,
}

const blsReducer = (
    state: BlsState = intialState, 
    action: AnyAction
): BlsState => {
    switch(action.type) {
        case actionType.SET_BLS_USER_ID : return setBlsUserIdInState(state, action.payload)
        case actionType.SET_SESSION_ID : return setSessionIdInState(state, action.payload)
        case actionType.SET_BLS_LENGTH : return setBlsLengthInState(state, action.payload)

        case actionType.SET_BLS_COMMAND : return setBlsCommandState(state, action.payload)
        case actionType.SET_TAPPER_SPEED_REPORT: return setBlsTapperSpeedState(state, action.payload)
        case actionType.SET_COLOR : return setBlsColorState(state, action.payload)
        case actionType.SET_COLOR_INTENSITY : return setBlsColorIntensityState(state, action.payload)
        case actionType.SET_TAPPER_INTENSITY_REPORT : return setBlsTapperIntensityInState(state, action.payload)
        case actionType.SET_TAPPER_FREQUENCY_REPORT : return setBlsTapperFrequencyInState(state, action.payload)
        case actionType.SET_ACTIVE : return setBlsActiveInState(state, action.payload)

        case actionType.CLEAN_UP_BLS_SESSION : return cleanBlsSessionData(state)

        case actionType.BLS_SET_SERIAL_PORT: return updateObj(state, {serialPort: action.payload});
        case actionType.BLS_SET_WRITER_PORT: return updateObj(state, {writerPort: action.payload});
        case actionType.BLS_SET_READER_PORT: return updateObj(state, {readerPort: action.payload});

        default:
            return state;
    }
}


const setBlsUserIdInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        blsUserId: payload
    })
}

const setSessionIdInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        sessionId: payload
    })
}

const setBlsLengthInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        blsLength: payload
    })
}





const setBlsCommandState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        blsCommand: payload
    })
}

const setBlsTapperSpeedState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        setTapperSpeedReport: payload
    })
}

const setBlsColorState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        color: payload
    })
}

const setBlsColorIntensityState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        colorIntensity: payload
    })
}

const setBlsTapperIntensityInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        tapperIntensityReport: payload
    })
}

const setBlsTapperFrequencyInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        tapperFrequencyReport: payload
    })
}

const setBlsActiveInState = (state: BlsState, payload: any) => {
    return updateObj(state, {
        active: payload
    })
}

const cleanBlsSessionData = (state: BlsState) => {
    return updateObj(state, {
        blsUserId: 'none',
        sessionId: 'none',
        blsLength: 600000,
        blsCommand: [2, 74, 50, 48, 48, 54, 56, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 57, 54, 48, 48, 57, 54, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 52, 48, 51, 14, 10],
        tapperSpeedReport: 150,
        color: 5,
        colorIntensity: 150,
        tapperIntensityReport: 4,
        tapperFrequencyReport: 3,
        active: false,
        serialPort: null,
        writerPort: null,
        readerPort: null,
    })
}



export default blsReducer;