import React from 'react'
import BlsNav from '../../components/navigationBar/blsNav'
import { useNavigate } from 'react-router-dom'
import BlsResults from '../../containers/Bls/BlsResults/BlsResults'

export default function BlsResult() {
  const navigate = useNavigate()
  return (
    <>
      <BlsNav
        buttonTitle={'Logout'}
        clicked={() => { 
          navigate('/')
        }}
      />
      <BlsResults/>
      
    </>
  )
}
