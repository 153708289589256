import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {signInUser , mapUserDetails}  from '../../store/actions/actionCreaters/userActions';
import { RootState } from '../../store/store';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Alert } from '@mui/material';

function Login({
  signInUser,
  authenticated,
  mapUserDetails
}: any) {

  const navigate = useNavigate();

  let [error, setError] = useState<string>('');


  useEffect(() => {
    console.log('render')
    console.log(authenticated)
    if(authenticated) {
      navigate('/bls_result');
    }
  },[authenticated]);




  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);

    if(error !== '') setError('');

    const email: string = `${data.get('email')}`;
    const password: string  = `${data.get('password')}`;

    try {
      
      let credenctial = await signInWithEmailAndPassword(auth, email, password);
      console.log(credenctial)


      mapUserDetails({
        name: 'Admin',
        uid: credenctial.user.uid,
        email: credenctial.user.email,
        app: 'Bls'
      })
      // signInUser({
      //   uid: credenctial.user.uid, 
      //   app: 'bls', 
      //   name: 'login User'
      // })
      
    } catch(error: any) { 

      console.log('hello')

        if (error.code.includes('auth/weak-password')) {
            setError('Please enter a stronger password');
        } else if (error.code.includes('auth/email-already-in-use')) {
            setError('This email is already in use')
        }
    }
  };


  return (
      <Container  maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
             {error !== '' ? <Alert severity="error" style={{width: '100%', marginTop: 20}}>{error}</Alert> : null}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  authenticated: state.userReducer.authenticated
})

const mapDispatchToProps = {
  signInUser,
  mapUserDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)