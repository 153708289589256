// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from 'firebase/auth';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCs1TlQc34tJfSZi6i5_DwnKM-OSRe9Tkk",
//   authDomain: "blsadmin-a5a9e.firebaseapp.com",
//   projectId: "blsadmin-a5a9e",
//   storageBucket: "blsadmin-a5a9e.firebasestorage.app",
//   messagingSenderId: "380070763309",
//   appId: "1:380070763309:web:14e99806e77f39791311a4",
//   measurementId: "G-2YHQGHJ6EG"
// };

// // Initialize Firebase
// export const auth = getAuth();
// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);
// export default app;


import {initializeApp} from 'firebase/app';
import { getAuth } from 'firebase/auth';

const app = initializeApp({
  apiKey: "AIzaSyCs1TlQc34tJfSZi6i5_DwnKM-OSRe9Tkk",
  authDomain: "blsadmin-a5a9e.firebaseapp.com",
  projectId: "blsadmin-a5a9e",
  storageBucket: "blsadmin-a5a9e.firebasestorage.app",
  messagingSenderId: "380070763309",
  appId: "1:380070763309:web:14e99806e77f39791311a4",
  measurementId: "G-2YHQGHJ6EG"
});

export const auth = getAuth();
export default app;


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDlVtgORGOruobO2jLicq7tnM4nOzNbNVI",
//   authDomain: "vilistus-cloud.firebaseapp.com",
//   projectId: "vilistus-cloud",
//   storageBucket: "vilistus-cloud.appspot.com",
//   messagingSenderId: "121118045121",
//   appId: "1:121118045121:web:5c33d8423104d4399b3540",
//   measurementId: "G-EL38C7NWBL"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// export const auth = getAuth();
// export default app 

