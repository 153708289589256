import React from 'react'
import SmallContainer from '../../components/containers/SmallContainer'
import CardContainer from '../../components/containers/CardContainer'
import BlsSetup from '../../containers/Bls/BlsDeviceSetup/BlsDeviceSetup'
import StartMindMirror from '../../containers/MindMirrorSetup/startMindMirror'
import styled from '@emotion/styled'

const Padding = styled('div')(() =>({
    padding: 30
}))

export default function ConnectVilistus() {
  return (
    <>
        <div style={{
            maxWidth: 600,
            margin: "10px auto 0px auto"
        }}>
            <CardContainer>
                <Padding>
                    <h2>Connect BLS</h2>
                    <h3>Step 1:</h3>
                    <p>Plug your BLS into your computer via usb or usb-c</p>
                    <h3>Step 2:</h3>
                    <p>Turn on your BLS</p>
                    <h3>Step 3:</h3>
                    <p>Press REQUEST CONNECTION - a box will appear from your browser, select the item that contains "MCP2221 USB-I2C/UART Combo" and press connect.</p>
                    <BlsSetup/>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
