import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import { UserForm } from '../../../interfaces/user';
import { stopVilistusStreamRequest } from '../../../store/actions/actionCreaters/backendCommands';
import CardContainer from '../../../components/containers/CardContainer';
import { liveSessionStatus } from '../../../store/actions/actionCreaters/simba';
import styled from '@emotion/styled'
import DiscreteSlider from '../../../components/sliders/sliders';
import { black } from '../../../assets/colors';
import { convertHexStringToDecimalArray, decimalToHex, generateHexAndDecimalColorValues, toHex } from '../BlsWeb/helpers/asciiToDecimal';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { saveBlsActions, startBlsSession } from '../../../http/bls';
import Timer from '../../../components/timer/timer';
import { useNavigate } from 'react-router-dom';
import { Button, Switch } from '@mui/material';
import { setBlsActive, setBlsColor, setBlsCommand, setColorIntensity, setSessionId, setTapperFrequencyReport, setTapperIntensityReport, setTapperSpeedReport } from '../../../store/actions/actionCreaters/bls';
// import BlsUserId from '../BlsUserId/BlsUserId';

interface BlsWebType {
    writerPort: any
    blsUserId: string
    blsLength: number
    // sessionId: string
    setSessionId: Function

    blsCommand: number[]
    tapperSpeedReport: number
    color: number
    colorIntensity: number
    tapperIntensityReport: number
    tapperFrequencyReport: number
    active: boolean

    setBlsCommand: Function
    setTapperSpeedReport: Function
    setBlsColor: Function
    setColorIntensity: Function
    setTapperIntensityReport: Function
    setTapperFrequencyReport: Function
    setBlsActive: Function
}

export const SetupBls = ({
    writerPort,
    blsUserId,
    blsLength,
    // sessionId,
    setSessionId,
    blsCommand,
    tapperSpeedReport,
    color,
    colorIntensity,
    tapperIntensityReport,
    tapperFrequencyReport,
    active,
    setBlsCommand,
    setTapperSpeedReport,
    setBlsColor,
    setColorIntensity,
    setTapperIntensityReport,
    setTapperFrequencyReport,
    setBlsActive

}: BlsWebType) => {

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 100,
      label: '100',
    }
  ];


  function blsColorPickerCommend(colour: number, colourIntensity: number) {
    let decimalColor = generateHexAndDecimalColorValues(colour, colourIntensity)
    console.log(decimalColor)
    let newCommand = [...blsCommand]
    decimalColor.decimal.forEach((number: number, i: number) => {
      newCommand[i + 19] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperSpeed(speed: number) {
    let tapperSpeed = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperSpeed)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 5] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperIntesity(speed: number) {
    let tapperIntensity = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperIntensity)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 37] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperFrequency(speed: number) {
    let tapperIntensity = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperIntensity)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 39] = number
    })
    setBlsCommand(newCommand)
  }

  function startBls() {
    let deciamalArray = convertHexStringToDecimalArray('JA')
    let newCommand = [...blsCommand]
    newCommand[1] = deciamalArray[0]
    newCommand[2] = deciamalArray[1]
    setBlsCommand(newCommand)
    setBlsActive(true)
  }

  function stopBls() {
    let deciamalArray = convertHexStringToDecimalArray('J2')
    console.log(deciamalArray)
    let newCommand = [...blsCommand]
    newCommand[1] = deciamalArray[0]
    newCommand[2] = deciamalArray[1]
    setBlsCommand(newCommand)
    setBlsActive(false)
  }

  let colors = ['red', 'lightgreen', 'blue', 'yellow', 'purple', 'lightblue', 'white', 'black']
  let colorButtons = colors.map((colour: string, i: number) => {
    return <ColorButton 
        key={i}
        color={colour}
        onClick={() => {
          blsColorPickerCommend(i, 40)
          setBlsColor(i)
        }}
        focus={color === i}
    />
  })

  return (
    <>
        <div style={{
            width: '100%', 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginTop: 0,
            marginBottom: 0
        }}>
            <h3>BLS Active</h3>
            <Switch 
                checked={active}
                onChange={() => {
                    if(!active) {
                        startBls()
                    } else {
                        stopBls()
                    }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>

        <h3 style={{marginBottom: 5}}>Sprite/Tapper Speed</h3>
        <DiscreteSlider
          default={tapperSpeedReport}
          step={1}
          min={0}
          max={100}
          changed={(_: any, newValue:  number) => {
            let deviceValue = +((250 * (newValue / 100)).toFixed(0))
            blsSetTapperSpeed(251 - deviceValue)
            setTapperSpeedReport(251 - deviceValue)
          }}
          marks={marks}
        />
        
        {/* <h2>Colour Intensity</h2>
        <DiscreteSlider
          default={colorIntensity}
          step={10}
          min={0}
          max={100}
          changed={(_: any, newValue:  number) => {
            let deviceValue = +((255 * (newValue / 100)).toFixed(0))
            console.log(deviceValue)
            blsColorPickerCommend(color, deviceValue)
            setColorIntensity(deviceValue)
          }}
          marks={marks}
        /> */}

        <h3 style={{marginBottom: 5}}>Tapper Intensity</h3>
        <DiscreteSlider
          default={tapperIntensityReport}
          step={1}
          min={0}
          max={100}
          changed={(_: any, newValue:  number) => {
            let deviceValue = +((8 * (newValue / 100)).toFixed(0))
            console.log(deviceValue)
            blsSetTapperIntesity(deviceValue)
            setTapperIntensityReport(deviceValue)
          }}
          marks={marks}
        />

        <h3 style={{marginBottom: 5}}>Tapper Frequency</h3>
        <DiscreteSlider
          default={tapperFrequencyReport}
          step={1}
          min={0}
          max={100}
          changed={(_: any, newValue:  number) => {
            let deviceValue = +((6 * (newValue / 100)).toFixed(0))
            console.log(deviceValue)
            blsSetTapperFrequency(deviceValue)
            setTapperFrequencyReport(deviceValue)
          }}
          marks={marks}
        />

        <h3 style={{marginBottom: 5}}>Colours</h3>
        <ColorButtonContainer>
            {colorButtons}
        </ColorButtonContainer>

    </>
  )
}

const mapStateToProps = (state: RootState) => ({
    writerPort: state.blsReducer.writerPort,
    blsUserId: state.blsReducer.blsUserId,
    blsLength: state.blsReducer.blsLength,
    blsCommand: state.blsReducer.blsCommand,
    tapperSpeedReport: state.blsReducer.tapperSpeedReport,
    color: state.blsReducer.color,
    colorIntensity: state.blsReducer.colorIntensity,
    tapperIntensityReport: state.blsReducer.tapperIntensityReport,
    tapperFrequencyReport: state.blsReducer.tapperFrequencyReport,
    active: state.blsReducer.active
})

const mapDispatchToProps = {
    setBlsCommand,
    setTapperSpeedReport,
    setBlsColor,
    setColorIntensity,
    setTapperIntensityReport,
    setTapperFrequencyReport,
    setBlsActive,
    setSessionId
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupBls)

const Padding = styled('div')(() =>({
  padding: 30
}))

const ColorButton = styled('button')((props: any) => ({
  cursor: 'pointer',
  padding: '15px 25px',
  backgroundColor: props.color,
  borderRadius: 5,
  opacity:props.focus ? 1 : .5,
  border: `
    ${props.focus ? '4px':'2px'} 
    solid 
    ${black(.3)}
  `,
  
}))

const ColorButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

