import React, {useState} from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import useInterval  from '../../HOC/UseInterval/UseInterval';
import { convertSeconds } from './formatTime';
import { black } from '../../assets/colors';


const useStyle = makeStyles(() => ({
  timer: {
    // fontSize: 40,
    fontWeight: 300,
    color: black(.6),
    marginTop: 0,
    marginBottom: 0
  },
}));

interface TimerProps {
    startTime: number
    pause: boolean
    timerIsZero: Function
    statusUpdate: Function
    // redirect: string
}

export default function Timer({
    startTime,
    pause,
    timerIsZero,
    statusUpdate,

    // redirect
}: TimerProps) {
    const classes = useStyle();
    const navigate = useNavigate();

    const [timer, setTimer] = useState(startTime);
    

    let play;

    if ((timer <= 0 && timer != null) || pause) {
        play = null;
    } else {
        play = 1000;
    }
    
    useInterval(() => {
        setTimer(timer - 1);
    }, play);

   
    if (timer <= 0) {
        timerIsZero();
        // navigate(redirect);
    }

    if(timer % 10 === 0 && timer !== 0) {
        statusUpdate();
    }


    const timerDisplay = convertSeconds(timer);
    
    return (
       <>
            <h2 className={classes.timer}>{timerDisplay}</h2>
       </>
    )
}









// import React, { Fragment, useEffect, useRef, useState } from "react";
// import { makeStyles } from "@material-ui/styles";
// import { Redirect } from "react-router-dom";
// import useInterval from "../../HOC/useInterval/useInterval";

// import { convertSeconds } from "./helperFunctions/timerFormat";

// const Styles = makeStyles((theme) => ({
//   timer: {
//     fontSize: 50,
//     fontWeight: 500,
//   },
// }));

// const Timer = (props) => {
//   const classes = Styles();
//   const [timer, setTimer] = useState(props.startTime);

//   let play;

//   if ((timer <= 0 && timer != null) || props.pause) {
//     play = null;
//   } else {
//     play = 1000;
//   }
  
//   useInterval(() => {
//     setTimer(timer - 1);
//   }, play);

//   let redirect = null;
//   if (timer <= 0) {
//     props.timerIsZero();
//     redirect = <Redirect to={props.redirect} />;
//   }

//   const timerDisplay = convertSeconds(timer);

//   return (
//     <Fragment>
//       {redirect}
//       <h1 className={classes.timer}>{timerDisplay}</h1>
//     </Fragment>
//   );
// };

// export default Timer;