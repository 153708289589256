import axios from 'axios'
import { User } from '../containers/Bls/BlsResults/BlsResults';
const baseUrl = process.env.REACT_APP_BASE_URL;

export const saveBlsActions = async (blsData: any) => {
    try {
        console.log('______SAVE_____')
        let response = await axios.post(`${baseUrl}/save_bls_actions`, {
            data: blsData
        })
        let message = response.data;
        return message;
    } catch(error) {
        console.log(error)
    }
}

export const getBlsUid = async (userId: string) => {
    try {
        let response = await axios.get(`${baseUrl}/get_bls_uid/${userId}`)
        let message = response.data;
        let uid = message.uid
        return uid;
    } catch(error) {
        return false
    }
}

export const startBlsSession = async (blsUserId: string, length: number, date: string) => {
    try {
        let response = await axios.post(`${baseUrl}/start_new_bls_session/${blsUserId}`, {
            length,
            date
        })
        let message = response.data;
        return message.sessionId;
    } catch(error) {
        console.log(error)
    }
}

export const getAllBlsData = async () => {
    try {
        let response = await axios.get(`${baseUrl}/get_all_bls_session_data`)
        let message = response.data;
        return message;
    } catch(error) {
        return false
    }
}

