import React from 'react'
import styled from '@emotion/styled'
import CardContainer from '../../components/containers/CardContainer'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BlsFinshButton from '../../containers/Bls/BlsFinishButton/BlsFinshButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const Padding = styled('div')(() =>({
    padding: 30,
}))

export default function NotFound() {
    const navigate = useNavigate()
  return (
    <>
        <div style={{
            maxWidth: 600,
            margin: "10px auto 0px auto"
        }}>
            <CardContainer>
                <Padding>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <ErrorOutlineIcon
                            style={{ fontSize: '180px', color: '#FD9A9A', margin: '10px' }}
                        />
                        <h3>404 Page not found</h3>
                    </div>
                    <br/>
                    <PrimaryButton clicked={() => navigate('/')}>Home</PrimaryButton>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
