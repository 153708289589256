import React from 'react'
import CardContainer from '../../components/containers/CardContainer'
import styled from '@emotion/styled'
import Login from '../../containers/Auth/Login'
import BlsNav from '../../components/navigationBar/blsNav'
import { useNavigate } from 'react-router-dom'


const Padding = styled('div')(() =>({
  padding: 30
}))

export default function Admin() {
    const navigate = useNavigate()
  return (
    <>
        <BlsNav
            buttonTitle={'Home'}
            clicked={() => navigate('/')}
        />
        <div style={{
            maxWidth: 600,
            margin: "10px auto 0px auto",
            textAlign: 'center'
        }}>

            <CardContainer>
                <Padding>
                <h2>Admin Login</h2>
                <Login/>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
