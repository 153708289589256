import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import CardContainer from '../../../components/containers/CardContainer';
import styled from '@emotion/styled'
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { startBlsSession } from '../../../http/bls';
import { useNavigate } from 'react-router-dom';
import { setBlsActive, setBlsColor, setBlsCommand, setColorIntensity, setSessionId, setTapperFrequencyReport, setTapperIntensityReport, setTapperSpeedReport } from '../../../store/actions/actionCreaters/bls';
import BlsControls from '../BlsControls/BlsControls';

interface BlsWebType {
    writerPort: any
    blsUserId: string
    blsLength: number
    setSessionId: Function
    blsCommand: number[]
    active: boolean
}

export const SetupBls = ({
    writerPort,
    blsUserId,
    blsLength,
    setSessionId,
    blsCommand,
    active,


}: BlsWebType) => {
  const navigate = useNavigate()

  useEffect(() => {
      if(writerPort && active) {
        console.log('active')
        sendCommandToDevice()
      } else if(writerPort && !active) {
        console.log('stop')
        stopDevice()
      }
  }, [blsCommand])


  async function sendCommandToDevice() {
      try {
        console.log(blsCommand)
          let command = new Uint8Array(blsCommand.length)
          blsCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          console.log(command)
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  async function stopDevice() {
      try {
          let stopCommand = [2,74,50,13,10]
          let command = new Uint8Array(stopCommand.length)
          stopCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  return (
    <div style={{
      maxWidth: 600,
      margin: "10px auto 0px auto"
  }}>
    <CardContainer>
      <Padding>
        <h2 style={{marginTop: 0}}>Setup your Initial BLS Configuration</h2>
        <BlsControls/>
        <br/>
        <PrimaryButton clicked={async () => {
            let sessionId = await startBlsSession(blsUserId, blsLength, Date())
            setSessionId(sessionId)
            navigate('/bls_live')
        }}>Start Bls Session</PrimaryButton>
      </Padding>
    </CardContainer>
  </div>
  )
}

const mapStateToProps = (state: RootState) => ({
    writerPort: state.blsReducer.writerPort,
    blsUserId: state.blsReducer.blsUserId,
    blsLength: state.blsReducer.blsLength,
    blsCommand: state.blsReducer.blsCommand,
    tapperSpeedReport: state.blsReducer.tapperSpeedReport,
    color: state.blsReducer.color,
    colorIntensity: state.blsReducer.colorIntensity,
    tapperIntensityReport: state.blsReducer.tapperIntensityReport,
    tapperFrequencyReport: state.blsReducer.tapperFrequencyReport,
    active: state.blsReducer.active
})

const mapDispatchToProps = {
    setBlsCommand,
    setTapperSpeedReport,
    setBlsColor,
    setColorIntensity,
    setTapperIntensityReport,
    setTapperFrequencyReport,
    setBlsActive,
    setSessionId
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupBls)

const Padding = styled('div')(() =>({
  padding: 15
}))


