import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import exportFromJSON from 'export-from-json'
import { getAllBlsData } from '../../../http/bls'
// import LargeContainer from '../../../components/containers/LargeContainer'
import { Button } from '@mui/material'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper';

const LargeContainer = styled(Paper)(({ theme }) => ({
    elevation: 3,
    height: '100%',
    width: 1400,
    padding: 20,
    borderRadius: 15,
    margin: '20px auto'
}));


const DataTitlesContainer = styled('div')(() =>({
    width: '100%',
    background: '#eee',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
}))
const DataContainer = styled('div')((): any =>({
    width: '100%',
    // height: 80,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    
}))


const DataTitle = styled('div')(() =>({
    paddingLeft: 5,
    height: 'inherit',
    flex: 1,
    border: '1px solid black'
}))

export interface User {
    id: number;
    name: string;
    email: string;
}



export const BlsResults = () => {

    const [blsData, setBlsData] = useState<any[]>([])

    useEffect(() => {
        getAllBlsData()
        .then(data => {
            console.log(data)
            setBlsData(data)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    async function exportCsvFile() {
        try {
            // let blsData = await getAllBlsData()
            const fileName = 'download'
            const exportType =  exportFromJSON.types.csv
            exportFromJSON({ data: blsData, fileName, exportType })
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <LargeContainer>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3>All BLS Session Data</h3>
                <Button onClick={exportCsvFile}>Download</Button>
            </div>

            <DataTitlesContainer>
                <DataTitle>
                    <h3>User</h3>
                </DataTitle>
                <DataTitle>
                    <h3>Sequence Number</h3>
                </DataTitle>
                {/* <DataTitle>
                    <h3>Session</h3>
                </DataTitle> */}
                <DataTitle>
                    <h3>Tapper Speed</h3>
                </DataTitle>
                <DataTitle>
                    <h3>Tapper Frequency</h3>
                </DataTitle>
                <DataTitle>
                    <h3>Colour</h3>
                </DataTitle>
                <DataTitle>
                    <h3>Active</h3>
                </DataTitle>
                <DataTitle>
                    <h3>Date</h3>
                </DataTitle>
            </DataTitlesContainer>

            {blsData.length > 0 ? blsData.map((bls: any, i: any) => {

                


                return <DataContainer key={i} style={{marginTop: bls.sequenceNumber === 1 ? 20 : 0}}>
                    <DataTitle>{bls.user}</DataTitle>
                    <DataTitle>{bls.sequenceNumber}</DataTitle>
                    {/* <DataTitle>{bls.session}</DataTitle> */}
                    <DataTitle>{bls.tapperSpeed}</DataTitle>
                    <DataTitle>{bls.tapperFrequency}</DataTitle>
                    <DataTitle>{`${bls.colour}`}</DataTitle>
                    <DataTitle>{`${bls.active}`}</DataTitle>
                    <DataTitle>{bls.date}</DataTitle>
                </DataContainer>
            }): null}

        </LargeContainer>
    )
}

const mapStateToProps = (state: RootState) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BlsResults)